/* Flexbox row content that will wrap */
.row_content {
    justify-content: center;
    flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  }
  
  /* General icon styles */
  .icons {
    padding: 4px;
    font-size: auto;
    width: auto;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: 400ms;
    transform: scale(1.1);
    margin-bottom: 10px; /* Add space between icons */
  }
  
  .icons:hover {
    transition: 500ms;
    transform: rotate(-30deg);
  }
  
  /* Specific styles for social icons */
  .fbIcon {
    background: #3B5998;
    color: white;   
  }
  
  .fbIcon:hover {
    background: #3B5998;
  }
  
  .twIcon {
    background-color: #000;
    color: white;  
  }
  
  .instaIcon {
    background-color: indianred;
  }
  
  .faLinkedinIcon {
    background-color: #3B5998;
  }
  .list_inline_footer{
    display: flex;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .row_content {
        flex-direction: column; /* Stack items vertically */
        text-align: center; /* Center align text */
        padding-bottom: 20px; /* Add padding to prevent content cutting off */
      }
    .icons {
        margin: 0 auto; /* Center icons */
      }
    .list_inline_footer {
      justify-content: center; /* Center the links on small screens */
      display: block !important; /* Make sure list items appear one below the other */
    }
  
    .list_inline_item_footer {
      display: block !important; /* Each item should take a full row on mobile */
      margin-bottom: 10px; /* Add space between items */
    }
    footer {
        padding: 20px;
        width: 100%; /* Ensure full width on mobile */
        box-sizing: border-box; /* Ensure padding and width don't overflow */
        overflow: hidden; /* Prevent content overflow */
      }
      .list_footer{
        display: none !important;
      }
  }
  