
.card{
    
}
.inflanar_Home_resp{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 45px !important;
    background: none !important;
}
/* General Styles */
.card_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default for desktop */
    gap: 16px; /* Space between cards */
  }
  
  .card_item {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  /* Responsive Grid */
  @media (max-width: 1150px) {
    .card_grid {
      grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    }
  }
  
  @media (max-width: 900px) {
    .card_grid {
      grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    }
  }
  
  @media (max-width: 450px) {
    .card_grid {
      grid-template-columns: repeat(1, 1fr); /* 1 card per row */
    }
  }
  


@media (max-width:767px) {
    .inflanar_Home_resp{
        display:block;
    }
  }