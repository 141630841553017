.aboutUsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
    background-color: #f8f7f7;
    font-family: Arial, sans-serif;
    margin-top: 80px;
  }
  
  .teamSection {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    justify-content: space-evenly;
  }
  
  .teamMember {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .profileImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  
  .name {
    font-size: 20px;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .role {
    font-size: 16px;
    color: #888;
  }
  
  .linkedinIcon, .instagramIcon {
    margin-top: 10px;
    color: #fff;
  }
  
  .linkedinIcon img, .instagramIcon img {
    width: 30px;
    height: 30px;
  }
  
  .aboutSection {
    width: 50%;
    padding-left: 20px;
  }
  
  .aboutSection h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .aboutSection p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .icons{
    padding: 4px;
    font-size: auto;
    width: auto;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: 400ms;
    transform: scale(1.1);
}
 .icons:hover{
    transition: 500ms;
    transform: rotate(-30deg);
}

.instaIcon{
    background-color: indianred;
}
.faLinkedinIcon{
    background-color: #5f88e0;
}