.navbar_main_header{
    padding: 0.1% 6% !important;
    box-shadow:0 2px 15px 0 rgb(0 0 0 / 10%);
    background: #fff;
    position: fixed !important;
    width: 100%;
    z-index: 100;
}
.navbar_logo{
    height: 50px;
}
