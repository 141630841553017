
.card {
    background-color: #fff !important;
    border-radius: 12px;
    width: 25%;
    box-shadow: 0 13px 10px -7px rgba(0, 0, 0, 0.1);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
    padding: 10px;
  }
.closebutton{
    margin-left: auto;
    width: fit-content;
    cursor: pointer;
}

@media(max-width:767px) {
  .card {
    width: 60%;
  }
}