.cards {
    width: 100%;
    height: auto;
  }
.card {
    background-color: #fff !important;
    width: 38.25%;
    border-radius: 12px;
    box-shadow: 0 13px 10px -7px rgba(0, 0, 0, 0.1);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  }
  .login_container{
  position: relative;
  height: auto;
  overflow: hidden;
  }
 
.form_width{
  width: 100%;
}
/* .img{
  background-image: url('../../../public/16092-NQGRQ3.jpg');
  background-size: cover;
  
} */
.left_side{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
}
.head_name{
  font-style: italic;
  font-size: 50px;
  color: crimson;
  position: relative;
  z-index: 1;
  opacity: 1;
}
.p_name{
  position: relative;
  z-index: 1;
  font-style: italic;
  font-size: 20px;
  color: #bad531;
  opacity: .9;
}
.i { 
    position: absolute; 
} 
  
.input_icons { 
    width: 100%;   
} 
.icon { 
    min-width: 60px;
    text-align: center; 
} 

.input_field { 
    width: 100%; 
    padding: 10px;   
    padding-left: 50px; 
    border-radius: 0%;
    border:none;
    background-color: rgb(232, 240, 254);
} 
 .radio_label{
  width: 100%;
  padding: 10px;   
  padding-left: 50px; 
  border-radius: 0%;
  border:none;
  background-color: rgb(232, 240, 254);
} 
.radio_text { 
  width: 100%;
  padding: 10px;   
  padding-left: 50px; 
  border-radius: 0%;
  border:none;
  background-color: rgb(232, 240, 254);
} 
.radio_submit{
    margin-top: 15px;
    text-align: center;
    padding: 0 120px 35px;
}
.forgot{
  cursor: pointer;
}

@media (max-width: 767px) {
.card{
    width: 95.25%;
  }
}
