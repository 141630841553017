
/* Apply general styles to the card */
.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
  }
  
  /* Apply specific styles to the influencerCard class */
  .influencerCard {
    background-color: #f8f9fa;
  }
  
  /* Apply styles to the verified tick */
  .verifiedTick {
    color: green;
    font-size: 18px;
    margin-right: 5px;
  }
  .closebutton {
    margin-left: auto;
    width: fit-content;
    cursor: pointer;
  top: 10px;
  right: 10px;
}
  
.imgBooked{
  max-width: 655px;
}
.paymentContainer{
  max-width: 40% !important;
  margin-left: 0 !important;
  border: 1px solid #ccc;
  padding: 10px;
}
#inp{
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  width:50%;
  font-size: 20px;
  outline: none;
}
.buttonsize{
  width: 200px;
  margin-top: 10px;
}
.cardslot{
  border-radius: 1.25rem !important;
  background-color: lightgray !important;
}
.textpurpose{
  background-color: lightgray !important;
}

@media (max-width:767px) {
  .imgBooked{
    max-width: 100%;
  }
  .paymentContainer{
    max-width: 100% !important;
   
  }
  #inp{ 
    width:100%;
  }
}

