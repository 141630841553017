.cards {
    width: 100%;
    height: auto;
  }
.card {
    background: linear-gradient(to bottom right, #dee3e5, #bfcbd5);
    width: 100%;
    height: 300px;
    box-shadow: 0 13px 10px -7px rgb(16, 15, 15);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
}
.form_width{
    width: 100%;
  }
.input_field { 
    width: 100%; 
    padding: 10px;   
    padding-left: 50px; 
    border-radius: 0%;
    border:none;  
} 
.input_icons { 
    width: 100%;   
} 
.button_search{
    width: 50%!important;
    height: 46px;
    margin-top: 7px;
    margin-left: 20px;
}
.shadedbox {
    min-height: auto !important;
    padding-top: 25px !important;

  }
.belowSearchimg{
    border: none !important;
    width: auto !important;
    height: auto !important;
    min-width: auto !important;
    margin-top: -50px;
    margin-bottom: -100px;
}

.carousel_container {
  width: 100%; /* Ensure the container takes the full available width */
  max-width: 1200px; /* Adjust this value based on how wide you want the container */
  margin: 0 auto; /* Center the container */
}

@media (max-width:400px) {
  .button_home{
    margin: 0 20px;
  }
}