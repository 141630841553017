.form_width{
    width: 100%;
  }
  .i { 
      position: absolute; 
  }
  .inline_block:first-child{
    margin-left: 0 !important;
  }
  .inline_block{
      display: inline-block;
      width: 100%;
  } 
  .radio_label{
    flex: 1; /* Distribute equal width */
    text-align: center; /* Center-align the label within each segment */
    cursor: pointer;
  }
  .input_icons { 
      width: 100%;   
  } 
  .icon { 
      min-width: 60px;
      text-align: center; 
  } 
  
  .input_field { 
      width: 100%; 
      padding: 10px;   
      padding-left: 50px; 
      border-radius: 0%;
      border:none;
      background-color: rgb(232, 240, 254);
  } 
  .input_social{
      width: 100%; 
      padding: 10px !important;   
      border-radius: 0%;
      border:none;
      background-color: rgb(232, 240, 254);
  }
   .radio_label{
    width: 100%;
    padding: 8px;   
    border-radius: 0%;
    border:1px;
    border: 1px solid #C1C1C1;
    border-radius: 4px;
  } 
  .radio_text { 
    width: 100%;
    padding-left: 10px;   
    border-radius: 0%;
    border:none;
  } 
  .radio_submit{
      margin-top: 15px;
      text-align: center;
      padding: 0 120px 35px;
  }
  .closebutton{
    margin-left: auto;
    width: fit-content;
    cursor: pointer;
    float: left;
}
.card {
  background-color: #fff !important;
  border-radius: 12px;
  width: fit-content;
  height: auto;
  box-shadow: 0 13px 10px -7px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  padding: 10px;
}
.paralleldivs {
  display: flex;
  justify-content: space-between;
 
  margin: 0 auto; /* Center the content if needed */
}
.buttonsize{
  width:100px;
  margin-top: 5px;
  float: left;
}


@media (max-width:767px) {
  .radio_text { 
    width: 100%;
    padding-left: 10px;   
    border-radius: 0%;
    border:none;
    font-size: 12px;
    font-weight: bold;
    line-height: 5px;
    position: relative;
    top: -2px;
  } 
}