
.beforetoday {
  background-color: grey;
  height: 20px;
  width: 10px;
  cursor: none;
}

.bookeddate {
  background-color: yellow;
  height: 20px;
  width: 10px;
}

.selecteddate {
  background-color: green;
  height: 20px;
  width: 10px;
}

.closebutton {
    margin-left: auto;
    width: fit-content;
    cursor: pointer;
  top: 10px;
  right: 10px;
}
.shortenedUrl{
        cursor: default;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block; 
        max-width: 100%;
}
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modal_actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.btn_primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 30px !important;
  cursor: pointer;
  border-radius: 5px;
}

.btn_secondary {
  background-color: #6c757d !important;
  color: white;
  border: none;
  padding: 10px 30px !important;
  cursor: pointer;
  border-radius: 5px;
}


@media (max-width:400px) {
  .beforetoday, .bookeddate, .selecteddate {
    overflow: visible; /* Ensure no text is being hidden */
    font-size: 9px;
    width: 100%;
    font-weight: bold;
    box-sizing: border-box; 
  }
}