.mt_10{
   margin-top: 10%;
   margin-left:5%
}

.fs_20{
    font-size: 60px;
}
sup{
    top: -0.001em;
    font-size: 135%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    }
#inp{
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background: transparent;
    width:100%;
    font-size: 20px;
    border-bottom: 2px solid rgb(7, 6, 7);
    outline: none;
  }