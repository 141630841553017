.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f0d4d4;
  transition: transform 0.2s ease-in-out;
  width: 100%; /* Adjust card width */
  margin: auto;
  height: 250px;
}

.card:hover {
  transform: scale(1.02); /* Optional hover effect */
}

.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  gap: 8px; /* Space between the label and name */
}

.label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.name {
  font-size: 1.2rem;
  color: #555;
}

.category {
  font-size: 1rem;
  margin: 0px 0 15px;
  color: #777;
}

.sessionFees {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  color: #666;
}
.icons {
padding: 4px;
font-size: auto;
width: auto;
text-align: center;
text-decoration: none;
cursor: pointer;
transition: 400ms;
transform: scale(1.1); /* Add space between icons */
vertical-align: middle;
}

.icons:hover {
transition: 500ms;
transform: rotate(-30deg);
}
.fbIcon {
  background: #3B5998;
  color: white;   
}

.fbIcon:hover {
  background: #3B5998;
}

.twIcon {
  background-color: #000;
  color: white;  
}

.instaIcon {
  background-color: #e310100a;
}

.card_font{
  font-size: 14px !important;
}
.verifiedTick {
  color: green;
  font-size: 18px;
  margin-right: 5px;
}
